<template>
    <uw-content title="出勤看板">

        <div class="w-checkin-content">

            <!-- 百度地图 -->
            <div id="BaiduMap" class="w-checkin-map"></div>

        </div>

    </uw-content>
</template>

<script>
export default {

    data () {
        return {
            checkindataForm: {
                opencheckindatatype: 3,
                starttime: null,
                endtime: null,
                useridlist: null
            }
        }
    },

    mounted () {
        this.BaiduMap()
        this.Getcheckindata()
    },

    methods: {

        // 百度地图
        BaiduMap() {
            let map = new BMapGL.Map("BaiduMap");
            let point = new BMapGL.Point(113.716014,22.999217);
            map.centerAndZoom(point, 18); 
        },

        // 获取打卡记录数据
        Getcheckindata() {
            this.$http.post('/9api/wecom-checkin/getcheckindata', this.checkindataForm).then(rps => {
                console.log(rps.data)
            })
        }
    }
}
</script>

<style lang="less" scoped>

    .w-checkin-content {
        width: 100%;
        height: 100%;
        position: relative;

        .w-checkin-map {
            width: 100%;
            height: 100%;
        }
    }
</style>